<template>
  <div class="page">
    <b-card
      text-variant="white"
      bg-variant="primary"
      class="position-static"
    >
      <b-card-title class="text-white flex-justify-between">
        <span>{{ data.title }}</span><span>Ticket #: {{ data.support_ticket_id }}</span>
      </b-card-title>
      <p>Last Update: {{ formatDate(data.updated_at) }}</p>
      <p>Created: {{ formatDate(data.created_at) }}</p>
      <b-badge
        class="mt-1"
        pill
        :variant="getVariant(data.status)"
      >
        {{ data.status }}
      </b-badge>
    </b-card>
    <b-card
      v-for="(thread, index) in data.support_ticket_comments"
      :key="index"
      :border-variant="isAdmin(thread)"
    >
      <b-card-body>
        <b-card-title>
          <b-avatar
            size="md"
            :src="thread.user.profile_image"
            class="mr-1"
          />
          {{ thread.user.name }}</b-card-title>
        <b-card-text>
          {{ thread.comment }}
        </b-card-text>
      </b-card-body>
      <b-card-footer>
        <small class="text-muted">Updated: {{ formatDate(thread.created_at) }}</small>
      </b-card-footer>
    </b-card>
    <b-card>
      <validation-observer
        ref="simpleRules"
        #default="{invalid}"
      >
        <b-form
          class="auth-forgot-password-form mt-2"
          @submit.prevent="validationForm"
        >
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Message"
              rules="required"
            >
              <b-form-textarea
                v-model="message"
                placeholder="Message"
                rows="8"
              />
              <p
                class="text-danger"
                style="margin-top:5px;"
              >
                {{ errors[0] }}
              </p>
            </validation-provider>
          </b-form-group>
          <b-button
            class="mt-1"
            variant="primary"
            type="submit"
            :disabled="invalid"
          >
            Submit
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardTitle, BBadge, BCardText, BCardBody, BCardFooter, BFormTextarea, BButton, BAvatar, BForm, BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCard, BCardTitle, BBadge, BCardText, BCardBody, BCardFooter, BFormTextarea, ValidationProvider, ValidationObserver, BButton, BAvatar, BForm, BFormGroup,
  },
  data() {
    return {
      required,
      message: '',
      data: {},
    }
  },
  created() {
    this.$store.dispatch('supports/getTicket', this.$route.params.id).then(response => {
      if (response.code === 200) {
        this.data = response.data
      }
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const data = {
            id: this.$route.params.id,
            message: this.message,
          }
          this.$store.dispatch('supports/addComment', data).then(response => {
            if (response.code === 200) {
              this.data.support_ticket_comments.push(response.data)
              this.message = ''
            }
          }).catch(err => {
            console.error(err)
          })
        }
      })
    },
    formatDate(date) {
      return new Date(date).toLocaleString('en-CA')
    },
    isAdmin(thread) {
      if (thread.user.memberType === 'superadmin') {
        return 'primary'
      }
      return null
    },
    getVariant(status) {
      switch (status) {
        case 'Active': return 'success'
        case 'Open': return 'success'
        case 'Closed': return 'danger'
        default: return 'dark'
      }
    },
  },
}
</script>

<style>

</style>
